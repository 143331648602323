import React from 'react'
import PropTypes from 'prop-types'
import Categories from './Categories'
import LocalizedLink from '../LocalizedLink'
import styled from 'styled-components'
import { H1, H1Content, P } from '../../styles/common/Typo'
import tw from 'twin.macro'
import { CategoriesText } from '.'

const ListItem = ({ node, categories }) => {
  return (
    <>
      <StyledWrapper>
        <StyledSectionLeft>
          <P><time>{node.data.date}</time> - {categories && <CategoriesText categories={categories} />}</P>
          <H1><LocalizedLink to={`/${node.uid}`}>{node.data.title.text}</LocalizedLink></H1>
        </StyledSectionLeft>
      </StyledWrapper>
    </>
  )
}

export default ListItem

ListItem.propTypes = {
  node: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}

const StyledWrapper = tw.div`
  flex
  flex-col
  md:flex-row
`;

const StyledSectionLeft = tw.div`
  p-0
  pt-6
  w-full
  md:py-6
  md:w-1/2
  md:block
`;

const StyledSectionRight = tw.div`
  p-0
  w-full
  flex
  md:py-6
  md:w-1/2
`;