import React from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import { Container, Section } from '../../styles/common/Layout'

const Listing = ({ posts }) => (
  <>
    {posts.map((post, index) => {
      let categories = false
      if (post.node.data.categories[0].category) {
        categories = post.node.data.categories.map(c => c.category.document.data.name)
      }
      return (
        <ListItem key={index} node={post.node} categories={categories} />
      )
    })}
  </>
)

export default Listing

Listing.propTypes = {
  posts: PropTypes.array.isRequired,
}