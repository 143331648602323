import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer'


const Content = tw.div`
  max-w-max pb-6
`
const CodeBlock = ({ input }) => {
  return (
    <>
      <Content dangerouslySetInnerHTML={{ __html: input.primary.code_block.html }} />
      <RichText render={input.primary.code_block.raw} htmlSerializer={htmlSerializer} />
    </>
  )
}

export default CodeBlock

CodeBlock.propTypes = {
  input: PropTypes.object.isRequired,
}