import styled from "styled-components";
import tw from "twin.macro";

export const H1 = styled.h1`
${tw`
  text-2xl
  lg:text-3xl
  font-bold
  mb-4
  `}
  ${props => props.NoBottomMargin && `
    margin-bottom: 0;
  `};
`;

export const H1Content = tw.h1`
  text-2xl font-bold mt-4 mb-1
`;

export const P = tw.p`
  leading-relaxed mb-3
`

export const A = styled.a`
  ${tw`transition-all font-bold`}
  max-width: max-content;
  display: inline-flex;
  span:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: black;
    position: relative;
    bottom: 0px;
    transition: width .5s;
  }
  span:hover::after {
    width: 100%;
  }
`;