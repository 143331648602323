import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import LocalizedLink from '../LocalizedLink'
import styled from 'styled-components';
import tw from 'twin.macro';

const A = styled(LocalizedLink)`
  ${tw`transition-all font-bold`}
  max-width: max-content;
  display: inline-flex;
  span:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: black;
    position: relative;
    bottom: 0px;
    transition: width .5s;
  }
  span:hover::after {
    width: 100%;
  }
`;

const Categories = ({ categories }) => (
  <>
    {categories.map((cat, i) => (
      <React.Fragment key={cat}>
        {!!i && ', '}
        <A to={`/categories/${kebabCase(cat)}`}><span>{cat}</span></A>
      </React.Fragment>
    ))}
  </>
)
export default Categories
Categories.propTypes = {
  categories: PropTypes.array.isRequired,
}