import styled from "styled-components";
import tw from "twin.macro";

export const TagWrapper = styled.div`
  ${tw`w-full flex flex-wrap self-center justify-start lg:justify-end`}
  ${(props) => props.FlexStart && tw`lg:justify-start`}
`

export const CategoryWrapper = styled.div`
  ${tw`w-full flex flex-wrap self-center justify-start lg:justify-start`}
`

export const Tag = styled.span`
  ${tw`
    mb-2
    mr-2
    text-xs
    font-semibold
    inline-flex
    py-2
    px-4
    cursor-pointer
    text-gray-600
    bg-lightgray
    hover:bg-darkergray
  `}
  ${props => props.NoFirstMargin && `
    &:first-of-type {
      margin-left: 0;
    }
  `};

`