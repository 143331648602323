import React from 'react'
import { Elements } from 'prismic-richtext'
import Prism from 'prismjs'

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key })
}

// Labels with this name will be inline code
const codeInline = ['text']
// Labels with these names will become code blocks
const codeBlock = ['javascript', 'css', 'scss', 'jsx', 'bash', 'json', 'diff', 'markdown', 'graphql']

const htmlSerializer = (type, element, content, children, index) => {
  var props = {}

  switch (type) {
    // First differentiate between a label and a preformatted field (e.g. the Code Block slice)
    case Elements.label: {
      // Use the inline code for labels that are in the array of "codeInline"
      if (codeInline.includes(element.data.label)) {
        return `<code class="language-${element.data.label}">${content}</code>`
      }
      // Use the blockquote for labels with the name "quote"
      if (element.data.label === 'quote') {
        return `<blockquote><p>${content}</p></blockquote>`
      }
      // Use the code block for labels that are in the array of "codeBlock"
      // Choose the right PrismJS highlighting with the label name
      if (codeBlock.includes(element.data.label)) {
        return `<pre class="language-${element.data.label}"><code class="language-${element.data.label
          }">${Prism.highlight(content, Prism.languages[element.label])}</code></pre>`
      }
      return null
    }
    case Elements.preformatted: {
      props = { className: `language--${element.label}` }

      if (codeBlock.includes(element.label)) {

        const text = Prism.highlight(
          element.text,
          Prism.languages[element.label]
        );
        return React.createElement('div', {}, `<pre class="language-${element.label}"><code class="language-${element.label}">${Prism.highlight(
          element.text,
          Prism.languages[element.label]
        )}</code></pre>`)

        return React.createElement('pre', { className: `language-${element.label}` },
          React.createElement('code', { className: `language-${element.label}` }, element.text))

      }
      return null
    }
    default: {
      return null
    }
  }
}

export default htmlSerializer