
import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import LocalizedLink from '../LocalizedLink'
import { CategoryWrapper, Tag } from '../../styles/common/Elements'

const Categories = ({ categories }) => {
  return (
    <CategoryWrapper>
      {categories.map((cat, i) => (
        <React.Fragment key={cat}>
          <LocalizedLink to={`/categories/${kebabCase(cat)}`}>
            <Tag NoFirstMargin>{cat}</Tag>
          </LocalizedLink>
        </React.Fragment>

      ))}
    </CategoryWrapper>)
}
export default Categories
Categories.propTypes = {
  categories: PropTypes.array.isRequired,
}